import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faLinkedin, faGithubSquare, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faBars, faEnvelope, faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

library.add(faBars, faLinkedin, faGithubSquare, faEnvelope, faEnvelopeSquare, faMedium);

import Header from './header';
import Socials from './socials';
import '../scss/app.scss';

interface LayoutProps {
  children: ReactElement | ReactElement[]
}

const Layout = ({ children }: LayoutProps): ReactElement => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header />
      <div className="page-layout">
        <main className="page-content">{children}</main>
      </div>
      <footer>
        <div className="copyright">
          © {new Date().getFullYear()}, Connie Lok
        </div>
        <Socials />
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
