import React, { ReactElement } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import '../scss/app.scss';
import MobileMenu from './mobileMenu';

const Header = (): ReactElement => {
  const [mobileMenuVisible, toggleMobileMenu] = React.useState(false);

  return (
    <header>
      <div className="header-inner">
        <h1>
          {/* <Link to="/">Connie Lok</Link> */}
        </h1>
        <button type="button" className="mobile-menu-button" aria-label="Mobile Menu" onClick={() => toggleMobileMenu(prev => !prev)}>
          <span className={`top-bun ${mobileMenuVisible ? 'close-mode' : ''}`}></span>
          <span className={`patty ${mobileMenuVisible ? 'close-mode' : ''}`}></span>
          {/* <span className="bottom-bun"></span> */}
        </button>
        <nav className="menu">
          <Link to="#about">About</Link>
          <Link to="#projects">Projects</Link>
        </nav>
      </div>
      {mobileMenuVisible && <MobileMenu toggleMobileMenu={toggleMobileMenu} />}
    </header>
  );
};

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// };

// Header.defaultProps = {
//   siteTitle: '',
// };

export default Header;
