import React, { ReactElement } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import '../scss/app.scss';

interface MobileMenuProps {
  toggleMobileMenu: Function,
}

const MobileMenu = ({ toggleMobileMenu }: MobileMenuProps): ReactElement => {
  const handleClick = () => {
    toggleMobileMenu(false);
  };

  return (
  // <nav className={`mobile-menu ${visible ? 'is-visible' : ''}`}>
    <nav className="mobile-menu">
      <Link to="#about" onClick={handleClick}>About</Link>
      <Link to="#projects" onClick={handleClick}>Projects</Link>
    </nav>
  );
};

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// };

// Header.defaultProps = {
//   siteTitle: '',
// };

export default MobileMenu;
