import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { string } from 'prop-types';

interface SocialsProps {
  classNames: string
}

const Socials = ({ classNames }: SocialsProps): ReactElement => (
  <div className={`socials ${classNames}`}>
    <a href="https://www.linkedin.com/in/connielok/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
      <FontAwesomeIcon icon={['fab', 'linkedin']} />
    </a>
    <a href="https://github.com/connielok" target="_blank" rel="noopener noreferrer" aria-label="Github">
      <FontAwesomeIcon icon={['fab', 'github-square']} />
    </a>
    <a href="mailto:connie.codes@gmail.com" target="_blank" rel="noopener noreferrer" aria-label="Email">
      <FontAwesomeIcon icon="envelope-square" />
    </a>
  </div>
);

Socials.propTypes = {
  classNames: string,
};

Socials.defaultProps = {
  classNames: '',
};

export default Socials;
